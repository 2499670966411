*{
    --red: #FF1A1A;
    --white: #fff;
    --black: rgb(0,0,0);
    --light-black: #1E1E1E;
    --veryLightCream: #F6EFEA;
    --lightGray: #757575;
    font-family: 'Outfit', 'Kavivanar', sans-serif;
    color: var(--black);
    font-size: 16px;
}
html{scroll-behavior: smooth;}
.light-black{background-color: var(--light-black);}
.black-background{background-color: var(--black);}
.red-background{background-color: var(--red);}
.white-background{background-color: var(--white);}
.veryLightCream-background{background-color: var(--veryLightCream);}
.black-text{color: var(--black);}
.white-text{color: var(--white);}
.red-text{color: var(--red);}
.light-gray-text{color: var(--lightGray);}
.flex{display: flex;}
.align-bottom{align-items: flex-end;}
.align-center{align-items: center;}
.justify-center{justify-content: center;}
.space-between{justify-content: space-between;}
.column-direction{flex-direction: column;}
.text-center{text-align: center;}
.row-direction{flex-direction: row;}
.grid{display: grid;}
.relative{position: relative;}
.no-bullets{list-style: none;}
.no-underline{text-decoration: none;}
.bold{font-weight: bold;}
.bolder{font-weight: bolder;}
.outfit{font-family: 'Outfit';}
.get-in-touch{
    background-color: var(--red);
    width: fit-content;
    border-radius: 50px;
    top: 90%;
    left: 75%;
    position: sticky;
}
.get-in-touchbtn{
    font-size: 1.8rem;
    padding: 2px 10px;
}

.get-in-touchbtn:hover{
    cursor: pointer;
    color: var(--black)
}